
import './App.css';
import AboutMe from './components/aboutMe.js'

function App() {
  return (
    <div className="App">
      <h3>Welcome Folks.</h3>
      <h3>My name is Joel. I'm a <span id="blogger">blogger</span>, editor, manager, <span id="coder">coder</span>, and
        more..</h3>
      <AboutMe />

      <div className="learn-more-cont">
        <h2>Want to know more about me?</h2>
        <div className="learn-more">
          <h4>My Writing & Editing Journey</h4>
          <i class="fa-solid fa-circle-arrow-right"></i>
        </div>
        <div className="learn-more">
          <h4>My Travel Journey So Far</h4>
          <i class="fa-solid fa-circle-arrow-right"></i>
        </div>
        <div className="learn-more">
          <h4>My Coding Journey</h4>
          <i class="fa-solid fa-circle-arrow-right"></i>
        </div>
        <div className="learn-more">
          <h4>Things I've made so far</h4>
          <i class="fa-solid fa-circle-arrow-right"></i>
        </div>
        <div className="learn-more">
          <h4>My Thoughts on Different Things</h4>
          <i class="fa-solid fa-circle-arrow-right"></i>
        </div>
      </div>
      <footer>
        <p>Built using React. Developing...</p>
      </footer>
    </div>
  );
}

export default App;
