function AboutMe() {
  return (
    <div className="about">
      <img class="about-img" src="/images/about_me_img.jpg" alt="about me"></img>
      <p className="about-text">Over the past eight years, I have played an active part in the technology industry, serving as an editor for a renowned publication. This adventure has allowed me to refine my skills as a writer, manager, and strategist.<br />
        <br />
        Learning and building things is a core part of my nature and to that end, I am on a journey to learn to code and ship products.
        <br />
        <br />
        I have always found mountains to be inspiring and being close to the snow clad peaks has helped me find my place of peace. My zest for exploration also involves bike rides and long car drives through the stunning mountain scenery.        <br />
        <br />
        As I navigate this life, I am looking to uncover my purpose and my  place in this incredible world.
      </p>
    </div>
  )
}

export default AboutMe;
